import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import SVG from 'react-inlinesvg'
import { injectSanitiseHtml } from '../../utils'
import Metrics from '../project-components/chapter-types/metrics'

import * as heroStyles from './hero.module.scss'

const Hero = props => {
    const renderLogo = () => {
        if (!props.logoType) {
            return null
        }
        if (props.logoType === 'png') {
            return <img src={props.logo} alt="" />
        }
        if (props.logoType === 'svg') {
            return (
                <SVG
                    src={props.logo}
                    cacheRequests
                    // loader={() => <span>loading...</span>}
                    // onError={error => console.log(error.message)}
                    // onLoad={(src, hasCache) => console.log(src, hasCache)}
                    preProcessor={code =>
                        code.replace(/fill="#.*"/g, `fill="${props.color}"`)
                    }
                    // title=""
                    // uniqueHash=""
                    // uniquifyIDs
                />
            )
        }
    }

    const getBackgroundColor = () => {
        if (props.backgroundColor && props.backgroundGradient) {
            return `linear-gradient(${props.backgroundGradient}, ${props.backgroundColor})`
        }
        return props.backgroundColor
    }

    return (
        <div
            className={heroStyles.projectContainer}
            style={{
                height: '100%',
                background: getBackgroundColor(),
                color: `${props.color}`,
            }}
        >
            <div className={heroStyles.title}>
                <h1 style={{ color: `${props.color}` }}>
                    {props.title}
                    {renderLogo()}
                </h1>
                {injectSanitiseHtml(props.tagline, 'h2', {
                    color: `${props.color}`,
                })}
            </div>
            {props.image ? (
                <div className={heroStyles.heroImage}>
                    <GatsbyImage
                        image={props.image.localFile.childImageSharp.gatsbyImageData}
                        key={props.image.localFile.childImageSharp.gatsbyImageData}
                        width={1600}
                    />
                </div>
            ) : null}
            <div className={heroStyles.servicesContainer}>
                <div className={heroStyles.servicesBox}>
                    <p style={{ color: `${props.color}` }}>Services</p>
                </div>
                <div className={heroStyles.descriptionBox}>
                    <div className={heroStyles.categories}>
                        {props.categories.map(category => (
                            <p
                                style={{ color: `${props.color}` }}
                                key={`hero-${category.name}`}
                            >
                                {category.name}{' '}
                            </p>
                        ))}
                        <hr style={{ background: `${props.color}` }} />
                    </div>
                    <div
                        className={heroStyles.description}
                        style={{ color: `${props.color}` }}
                    >
                        {injectSanitiseHtml(props.description)}
                    </div>
                </div>
            </div>
            {props.metrics ? (
                <div>
                    <Metrics
                        metrics={props.metrics || []}
                        backgroundColor={props.backgroundColor}
                        useCustomTextColor={true}
                        textColor={props.color}
                    />
                </div>
            ) : null}
            <div className={heroStyles.horizontalBreak}></div>
        </div>
    )
}

export default Hero
