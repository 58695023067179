import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/project-components/hero'
import Chapters from '../components/project-components/chapters'
import Portfolio from '../components/home-components/portfolio'

export const query = graphql`
    query ProjectPages($id: String!) {
        wpProject(id: { eq: $id }) {
            title
            slug
            categories {
                nodes {
                    name
                }
            }
            project {
                colourBottom
                colourTop
                textColor
                helpSectionBackgroundColour
                helpSectionTitle
                stageBackgroundColourType
                projectClientBrandColour
                projectTagline
                projectDescription
                overlayColor
                overlayOpacity
                relatedProject {
                    ... on WpProject {
                        title
                    }
                }
                metrics {
                    ... on WpProject_Project_Metrics_NumberMetric {
                        __typename
                        fieldGroupName
                        integer
                        title
                    }
                    ... on WpProject_Project_Metrics_FactMetric {
                        __typename
                        factDescription
                        fieldGroupName
                        unit
                        title
                        scale
                    }
                    ... on WpProject_Project_Metrics_DescriptionMetric {
                        __typename
                        count
                        description
                        fieldGroupName
                        title
                    }
                }
                projectHeroImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                        }
                    }
                }
                projectCardImage {
                    sourceUrl
                    localFile {
                        extension
                    }
                }
                chapters {
                    contentBlock {
                        __typename
                        ... on WpProject_Project_chapters_ContentBlock_Quote {
                            backgroundColour
                            client
                            quotee
                            statement
                            textColor
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Metric {
                            __typename
                            backgroundColour
                            metrics {
                                __typename
                                ... on WpProject_Project_chapters_ContentBlock_Metric_Metrics_DescriptionMetric {
                                    count
                                    description
                                    title
                                }
                                ... on WpProject_Project_chapters_ContentBlock_Metric_Metrics_FactMetric {
                                    factDescription
                                    unit
                                    title
                                    scale
                                }
                                ... on WpProject_Project_chapters_ContentBlock_Metric_Metrics_NumberMetric {
                                    integer
                                    title
                                }
                            }
                            noMetrics
                            textColour
                            useCustomTextColour
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Image {
                            backgroundColour
                            blockBackgroundColour
                            fieldGroupName
                            description
                            heading
                            horizontalAlignment
                            imageAlignment
                            imageScaleFactor
                            imageTopPositionShift
                            overlayColor
                            overlayOpacity
                            showHeadings
                            showText
                            subheading
                            textColour
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                            videoInstead {
                                mediaItemUrl
                            }
                            metricsFields {
                                fieldGroupName
                                metricDescription
                            }
                            relatedLogo {
                                sourceUrl
                            }
                        }
                        ... on WpProject_Project_chapters_ContentBlock_ImageScroller {
                            backgroundColour
                            description
                            heading
                            horizontalAlignment
                            lastFirst
                            resolution
                            showHeadings
                            showText
                            subheading
                            textColour
                            pics {
                                image {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                        ... on WpProject_Project_chapters_ContentBlock_ImageCollection {
                            backgroundColour
                            description
                            heading
                            horizontalAlignment
                            imageOverlay
                            mixedMedia {
                                __typename
                                ... on WpProject_Project_chapters_ContentBlock_ImageCollection_MixedMedia_Video {
                                    autoplay
                                    loop
                                    noControls
                                    video {
                                        mediaItemUrl
                                    }
                                }
                                ... on WpProject_Project_chapters_ContentBlock_ImageCollection_MixedMedia_Image {
                                    image {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    layout: CONSTRAINED
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            showHeadings
                            showText
                            sidetextAlignment
                            stickyText
                            subheading
                            textColour
                            textOnTheSide
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Slider {
                            backgroundColour
                            description
                            heading
                            horizontalAlignment
                            showHeadings
                            showText
                            sliderResolution
                            subheading
                            textColour
                            layout
                            cards {
                                cardColour
                                fieldGroupName
                                heading
                                showImage
                                text
                                image {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Video {
                            autoplay
                            backgroundColour
                            controls
                            fieldGroupName
                            video {
                                mediaItemUrl
                            }
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Services {
                            backgroundColour
                            fieldGroupName
                            servicesBlockTitle
                            textColour
                            servicePosts {
                                serviceDescription
                                serviceName
                            }
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Logos {
                            backgroundColour
                            description
                            horizontalAlignment
                            heading
                            showHeadings
                            showText
                            subheading
                            textColour
                        }
                        ... on WpProject_Project_chapters_ContentBlock_Text {
                            backgroundColour
                            description
                            heading
                            horizontalAlignment
                            showHeadings
                            showText
                            subheading
                            textColour
                        }
                    }
                }
            }
        }
    }
`

const Project = (props) => {
    const project = props.data.wpProject
    const backgroundColor =
        project.project.stageBackgroundColourType === 'gradient'
            ? project.project.colourBottom
            : project.project.projectClientBrandColour
    const backgroundGradient =
        project.project.stageBackgroundColourType === 'gradient'
            ? project.project.colourTop
            : null
    const headerBackground =
        project.project.stageBackgroundColourType === 'gradient'
            ? project.project.colourTop
            : backgroundColor
    const textColor = project.project.textColor
    return (
        <Layout backgroundColor={headerBackground} color={textColor}>
            <SEO title={project.title} />
            <Hero
                backgroundColor={backgroundColor}
                backgroundGradient={backgroundGradient}
                color={textColor}
                tagline={project.project.projectTagline}
                description={project.project.projectDescription}
                title={project.title}
                image={project.project.projectHeroImage}
                logoType={
                    project.project.projectCardImage
                        ? project.project.projectCardImage.localFile.extension
                        : null
                }
                logo={
                    project.project.projectCardImage
                        ? project.project.projectCardImage.sourceUrl
                        : ''
                }
                metrics={
                    project.project.metrics ? project.project.metrics : null
                }
                categories={project.categories.nodes}
            />
            <Chapters
                chapters={project.project.chapters}
                pageId={props.pageContext.id}
            />
            <Portfolio
                relatedTitle={
                    project.project.relatedProject
                        ? project.project.relatedProject.title
                        : ''
                }
            />
            <div style={{ width: '100%', height: '100px' }} />
        </Layout>
    )
}

export default Project
